export class CommonLib {
  /**
   * Returns the index of the last element in the array where predicate is true, and -1
   * otherwise.
   * @param array The source array to search in
   * @param predicate find calls predicate once for each element of the array, in descending
   * order, until it finds one where predicate returns true. If such an element is found,
   * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
   */
  public static findLastIndex<T>(
    array: Array<T>,
    predicate: (value: T, index: number, obj: T[]) => boolean
  ): number {
    const reverse = array.slice().reverse();
    const index = reverse.findIndex(predicate);
    if (index !== -1) {
      return array.length - 1 - index;
    }
    return -1;
  }
}
