export var CallWaiterReasons;
(function (CallWaiterReasons) {
    CallWaiterReasons["PAYMENT_CASH"] = "PAYMENT_CASH";
    CallWaiterReasons["PAYMENT_CARD"] = "PAYMENT_CARD";
    CallWaiterReasons["PAYMENT_DEFAULT"] = "PAYMENT_DEFAULT";
    CallWaiterReasons["CALL_WAITER_SHISHA_COAL"] = "CALL_WAITER_SHISHA_COAL";
    CallWaiterReasons["CALL_WAITER_OTHERS"] = "CALL_WAITER_OTHERS";
    CallWaiterReasons["CALL_WAITER_DEFAULT"] = "CALL_WAITER_DEFAULT"; // To be the Reason when no call waiter services available
})(CallWaiterReasons || (CallWaiterReasons = {}));
export const CallWaiterMessage = new Map([
    [CallWaiterReasons.CALL_WAITER_OTHERS, "Ein Kellner am Tisch {0}"],
    [CallWaiterReasons.CALL_WAITER_SHISHA_COAL, "Kohle am Tisch {0}"],
    [CallWaiterReasons.CALL_WAITER_DEFAULT, "Ein Kellner am Tisch {0}"],
    [CallWaiterReasons.PAYMENT_CARD, "Zahlung Karte am Tisch {0}"],
    [CallWaiterReasons.PAYMENT_CASH, "Zahlung Bar am Tisch {0}"],
    [CallWaiterReasons.PAYMENT_DEFAULT, "Zahlung am Tisch {0}"],
]);
export const CallWaiterServiceName = new Map([
    [CallWaiterReasons.CALL_WAITER_OTHERS, "Sonstiges"],
    [CallWaiterReasons.CALL_WAITER_SHISHA_COAL, "Kohle"],
    [CallWaiterReasons.CALL_WAITER_DEFAULT, "Default"],
    [CallWaiterReasons.PAYMENT_CARD, "Karte"],
    [CallWaiterReasons.PAYMENT_CASH, "Bar"],
    [CallWaiterReasons.PAYMENT_DEFAULT, "Default"],
]);
