export const Platinum = '#e4e4e4';
export const Cultured = '#F9FAFA';

export const DimGray = '#6B6A64';

// Black
export const RaisinBlack = '#202125';

export const RaisinBlackSixOpacity = 'rgba(32, 33, 37, 0.64)';

// Primary Color (Celestial Blue)

export const CelestialBlue04 = 'rgba(0, 157, 224, 0.4)';
export const CelestialBlue08 = 'rgba(0, 157, 224, 0.08)';
export const CelestialBlue96 = 'rgba(0, 157, 224, 0.96)';
