import { CreateOrderItemInterface } from '@platepal/interfaces';

import { CartItem } from '../../models';

export const createOrderDto = (
  cartItems: CartItem[]
): CreateOrderItemInterface[] => {
  const orderItems: CreateOrderItemInterface[] = [];
  for (const cartItem of cartItems) {
    let cartItemQuantity = cartItem.quantity;
    while (cartItemQuantity > 0) {
      orderItems.push({
        orderedItemId: cartItem.menuItemId,
        modifiers:
          cartItem.modifiers?.map((modifier) => ({
            modifierId: modifier.modifierId,
            quantity: modifier.quantity,
            name: modifier.name,
          })) ?? [],
      });
      cartItemQuantity -= 1;
    }
  }
  return orderItems;
};

export const modifiersEqual = (
  cartItem: CartItem,
  otherCartItem: CartItem
): boolean => {
  if (cartItem.modifiers.length !== otherCartItem.modifiers.length) {
    return false;
  }
  for (const modifier of cartItem.modifiers ?? []) {
    if (
      otherCartItem.modifiers?.findIndex(
        (mod) =>
          mod.modifierId === modifier.modifierId &&
          mod.quantity === modifier.quantity
      ) === -1
    ) {
      return false;
    }
  }
  return true;
};
