import { Box, Stack, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { formatCurrency } from '@platepal/utils';
import { isPast } from 'date-fns';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MainLayout from '../../layout/main-layout';
import { CartItem as CartItemModel } from '../../models';
import { RoutePaths } from '../../routes-path';
import OrderEmpty from './components/order-empty';
import OrderHistory from './components/order-history';

const Orders = () => {
  const navigate = useNavigate();
  const navigateToMenu = () => {
    navigate(RoutePaths.MENU);
  };

  const [storedOrders, setStoredOrders] = useState<{
    orders: CartItemModel[];
    expiry: number;
  } | null>(() => JSON.parse(localStorage.getItem('orders') || '[]'));
  const [totalAmount, setTotalAmount] = useState(0);
  useEffect(() => {
    if (!storedOrders) return;
    if (isPast(new Date(storedOrders.expiry))) {
      localStorage.removeItem('orders');
      setStoredOrders(null);
    } else {
      const total =
        storedOrders.orders?.reduce(
          (acc: number, item: CartItemModel) =>
            acc + item.price.gross * item.quantity,
          0
        ) || 0;
      setTotalAmount(total);
    }
  }, [storedOrders]);

  return (
    <>
      <MainLayout>
        {storedOrders?.orders?.length ? (
          <Box sx={{ px: 2, py: 4 }}>
            <Box height="92%" display="flex" flexDirection="column">
              <OrderHistory orders={storedOrders?.orders} />
            </Box>
            <Divider sx={{ borderBottomWidth: 5, my: 2 }} />
            <Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography gutterBottom variant="h4" component="div">
                  {t('orders.total')}
                </Typography>
                <Typography gutterBottom variant="h4" component="div">
                  {formatCurrency(totalAmount)}
                </Typography>
              </Stack>
            </Box>
          </Box>
        ) : (
          <OrderEmpty onClose={navigateToMenu} />
        )}
      </MainLayout>
    </>
  );
};

export default Orders;
