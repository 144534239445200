import { Box, Button, SxProps, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

const imgStyle: SxProps = {
  maxHeight: '200px',
  mb: 8,
};

export interface CallWaiterProps {
  onBack: () => void;
}

const CallWaiter: React.FC<CallWaiterProps> = ({ onBack }) => {
  return (
    <>
      <Box
        height="92%"
        sx={{ textAlign: 'center', px: 2 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box>
          <Box
            component="img"
            src="/img/service/waiter.svg"
            alt={t('cart.empty-cart')}
            sx={imgStyle}
          />
          <Typography mb={2} variant="h3">
            {t('call-waiter.title')}
          </Typography>
          <Typography>{t('call-waiter.caption')}</Typography>
        </Box>
      </Box>
      <Box sx={{ px: 2 }}>
        <Button onClick={() => onBack()} fullWidth variant="contained">
          {t('waiter-payment.back-to-services')}
        </Button>
      </Box>
    </>
  );
};

export default CallWaiter;
