import { useAppSelector } from '../store';

export const useLoading = (): boolean => {
  return useAppSelector(
    (state) =>
      state.order.loading ||
      state.restaurant.loading ||
      state.customerService.loading
  );
};
