import { CreateOrderInterface, OrderItemInterface } from '@platepal/interfaces';

import { apiAxios } from '../axios';

export class OrdersService {
  public async createOrder(
    restaurantId: string,
    tabledId: string,
    orderDto: CreateOrderInterface
  ): Promise<OrderItemInterface[]> {
    const res = await apiAxios.post(
      `orders/${restaurantId}/table/${tabledId}`,
      orderDto
    );
    return res.data;
  }
}
