import { Box, SxProps } from '@mui/material';
import React from 'react';

import { DimGray } from '../../config/theme/colors';

const iconStyle: SxProps = {
  width: '40px',
  height: '3px',
  backgroundColor: DimGray,
  border: '1px solid',
  borderColor: DimGray,
  borderRadius: '4px',
};

const IphoneDragIcon: React.FC = () => {
  return <Box sx={iconStyle} />;
};

export default IphoneDragIcon;
