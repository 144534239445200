import { add, isPast, isValid } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

export const useActiveSession = (justScanned: string) => {
  const [isActive, setIsActive] = useState(true);
  const sessionExpiryTime = process.env.REACT_APP_USER_SESSION_EXPIRY_MINS
    ? +process.env.REACT_APP_USER_SESSION_EXPIRY_MINS
    : 1;

  const handleSessionTimeout = useCallback(() => {
    const expiryDate = localStorage.getItem('scanExpiryDate');
    if (!expiryDate || isPast(new Date(expiryDate))) {
      localStorage.removeItem('scanExpiryDate');
      setIsActive(false);
    }
  }, []);

  useEffect(() => {
    if (isValid(new Date(justScanned))) {
      if (!isPast(new Date(justScanned))) {
        localStorage.setItem('scanExpiryDate', justScanned);
        setIsActive(true);
      } else {
        setIsActive(false);
      }
      // Remove 'qr' from URLSearchParams
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('qr');
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.pushState(null, '', newUrl);
    } else if (justScanned === 'true') {
      const expiryDate = add(new Date(), { minutes: sessionExpiryTime });
      localStorage.setItem('scanExpiryDate', expiryDate.toISOString());

      // Remove 'qr' from URLSearchParams
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('qr');
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.pushState(null, '', newUrl);

      setIsActive(true); // Reset the session to active
    } else {
      handleSessionTimeout();
    }

    const intervalId = setInterval(handleSessionTimeout, 60000); // Check every minute
    return () => clearInterval(intervalId);
  }, [justScanned, sessionExpiryTime, handleSessionTimeout]);

  return isActive;
};
