import { Box, Button, SxProps, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

const imgStyle: SxProps = {
  maxHeight: '200px',
  mb: 8,
};

export interface ServiceErrorScreenProps {
  errorMsg: string;
  onBack: () => void;
}

const ServiceErrorScreen: React.FC<ServiceErrorScreenProps> = ({
  errorMsg,
  onBack,
}) => {
  return (
    <>
      <Box
        height="92%"
        sx={{ textAlign: 'center', px: 2 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box>
          <Box
            component="img"
            src="/img/service/customer-service-error.svg"
            alt={t('cart.empty-cart')}
            sx={imgStyle}
          />
          <Typography mb={2} variant="h3">
            {t('errors.customer-service.title')}
          </Typography>
          <Typography>{t(errorMsg)}</Typography>
        </Box>
      </Box>
      <Box sx={{ px: 2 }}>
        <Button
          onClick={() => onBack()}
          color="secondary"
          fullWidth
          variant="contained"
        >
          {t('waiter-payment.back-to-services')}
        </Button>
      </Box>
    </>
  );
};

export default ServiceErrorScreen;
