import { createSlice } from '@reduxjs/toolkit';

import { calculateCartTotalPrice } from '../../libs';
import { modifiersEqual } from '../../libs/order-lib';
import { CartItem } from '../../models';
import { CartState } from './cart-state';

const cartInitialState: CartState = {
  cartItems: [],
  totalPrice: {
    net: 0,
    gross: 0,
    vat: 0,
    vatRate: 0,
  },
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState: cartInitialState,
  reducers: {
    resetCart: (state: CartState) => {
      state.cartItems = [];
      state.totalPrice = {
        net: 0,
        gross: 0,
        vat: 0,
        vatRate: 0,
      };
    },

    addToCart: (state: CartState, action) => {
      const selectedItem = action.payload as CartItem;
      const itemAlreadyInCart = state.cartItems.find(
        (item) =>
          item.menuItemId === selectedItem.menuItemId &&
          modifiersEqual(selectedItem, item)
      );
      if (itemAlreadyInCart) {
        itemAlreadyInCart.quantity += selectedItem.quantity;
      } else {
        const cardItem: CartItem = { ...selectedItem };
        state.cartItems.push(cardItem);
      }
      state.totalPrice = calculateCartTotalPrice(state.cartItems);
    },
    changeQuantity: (state: CartState, action) => {
      const payload = action.payload as {
        id: string;
        quantity: number;
      };
      const cartItem = state.cartItems.find((item) => item.id === payload.id);
      if (cartItem) {
        cartItem.quantity = payload.quantity;
        state.cartItems = state.cartItems.filter((item) => item.quantity > 0);
      }
      state.totalPrice = calculateCartTotalPrice(state.cartItems);
    },
  },
});

export const cartActions = cartSlice.actions;
