import { CallWaiterDto } from '@platepal/interfaces';

import { apiAxios } from '../axios';

export class CustomerService {
  public async callWaiter(
    restaurantId: string,
    tabledId: string,
    callWaiterDto: CallWaiterDto
  ): Promise<CallWaiterDto[]> {
    const res = await apiAxios.post(
      `waiter/callwaiter/${restaurantId}/table/${tabledId}`,
      callWaiterDto
    );
    return res.data;
  }
}
