import { useMemo } from 'react';

import { getRestaurantData, restaurantActions } from '../../store/restaurant';
import { useAppDispatch } from '../store';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRestaurantActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(
    () => ({
      getRestaurantData: (id: string) => dispatch(getRestaurantData({ id })),
      resetError: () => dispatch(restaurantActions.resetError()),
      setTableId: (tableId: string) =>
        dispatch(restaurantActions.setTableId(tableId)),
    }),
    [dispatch]
  );
};
