/* eslint-disable @typescript-eslint/no-floating-promises */

import { CallWaiterDto } from '@platepal/interfaces';
import { useMemo } from 'react';

import {
  callWaiter,
  customerServiceActions,
} from '../../store/customer-sevice';
import { useAppDispatch } from '../store';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCustomerServiceActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(
    () => ({
      resetError: () => dispatch(customerServiceActions.resetError()),
      callWaiter: (
        restaurantId: string,
        tableId: string,
        callWaiterDto: CallWaiterDto,
        onSuccess: () => void
      ) =>
        dispatch(
          callWaiter({ restaurantId, tableId, callWaiterDto, onSuccess })
        ),
    }),
    [dispatch]
  );
};
