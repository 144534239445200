import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { CallWaiterReasons, CallWaiterServiceName } from '@platepal/interfaces';

type RadioServiceSelectorProps = {
  services: CallWaiterReasons[];
  onClickReson: (reason: CallWaiterReasons) => void;
};

const RadioServiceSelector = ({
  services,
  onClickReson,
}: RadioServiceSelectorProps) => {
  return (
    <FormControl sx={{ width: '100%' }}>
      <RadioGroup aria-labelledby="radio-services" name="radio-services">
        {services.map((service) => {
          return (
            <FormControlLabel
              key={`service-${service.toString()}`}
              value={CallWaiterServiceName.get(service)}
              control={
                <Radio
                  onClick={() => {
                    onClickReson(service);
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  {CallWaiterServiceName.get(service)}
                </Typography>
              }
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioServiceSelector;
