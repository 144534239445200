import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

const ScanQrCode = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100vh',
        px: 2,
      }}
    >
      <>
        <Box
          sx={{ height: '200px', mb: 2 }}
          component="img"
          src="/img/qr-code.gif"
          alt={t('scan-qr.title')}
        />
        <Typography sx={{ textAlign: 'center' }} gutterBottom variant="h3">
          {t('scan-qr.title')}
        </Typography>
        <Typography sx={{ textAlign: 'center' }}>
          {t('scan-qr.subtitle')}
        </Typography>
      </>
    </Box>
  );
};

export default ScanQrCode;
