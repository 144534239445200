import { Link } from '@mui/material';

export const Logo = () => (
  <Link href="https://getplatepal.de" target="_blank" rel="noopener noreferrer">
    <img
      src="img/logo.svg"
      alt="logo"
      style={{ height: '40px', width: '40px' }}
    />
  </Link>
);
