import { LegalLayout } from '../../layout/LegalLayout';

export const Impressum = () => {
  return (
    <LegalLayout>
      <h1>Impressum</h1>

      <p>
        Yousef Aljafra
        <br />
        Externest, 1<br />
        44892 Bochum
      </p>

      <h2>Kontakt</h2>
      <p>
        Telefon: 015202005287
        <br />
        E-Mail: platepal.dev@gmail.com
      </p>

      <p>
        Quelle: <a href="https://www.e-recht24.de">eRecht24</a>
      </p>
    </LegalLayout>
  );
};
