import { TypographyOptions } from '@mui/material/styles/createTypography';

import palette from './palette';

const defaultColor = '#000';

const typography: TypographyOptions = {
  h1: {
    color: palette.text?.primary ?? defaultColor,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif',
    fontWeight: 700,
    fontSize: '35px',
    letterSpacing: '-0.24px',
    lineHeight: '40px',
  },
  h2: {
    color: palette.text?.primary ?? defaultColor,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif',
    fontWeight: 700,
    fontSize: '29px',
    letterSpacing: '-0.24px',
    lineHeight: '32px',
  },
  h3: {
    color: palette.text?.primary ?? defaultColor,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif',
    fontWeight: 700,
    fontSize: '22px',
    letterSpacing: '-0.06px',
    lineHeight: '26px',
  },
  h4: {
    color: palette.text?.primary ?? defaultColor,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif',
    fontWeight: 700,
    fontSize: '20px',
    letterSpacing: '-0.06px',
    lineHeight: '24px',
  },
  h5: {
    color: palette.text?.primary ?? defaultColor,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif',
    fontWeight: 700,
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  h6: {
    color: palette.text?.primary ?? defaultColor,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif',
    fontWeight: 700,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  subtitle1: {
    color: palette.text?.primary ?? defaultColor,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif',
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '25px',
  },
  subtitle2: {
    color: palette.text?.secondary ?? defaultColor,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '21px',
  },
  body1: {
    color: palette.text?.primary,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif',
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '23px',
  },
  body2: {
    color: palette.text?.secondary ?? defaultColor,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif',
    fontSize: '12px',
    letterSpacing: '-0.04px',
    lineHeight: '18px',
  },
  button: {
    color: palette.text?.primary,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif',
    fontSize: '14px',
    textTransform: 'none',
  },
  caption: {
    color: palette.text?.secondary ?? defaultColor,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif',
    fontSize: '11px',
    letterSpacing: '0.33px',
    lineHeight: '13px',
  },
  overline: {
    color: palette.text?.secondary ?? defaultColor,
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif',
    fontSize: '11px',
    fontWeight: 500,
    letterSpacing: '0.33px',
    lineHeight: '13px',
    textTransform: 'uppercase',
  },
};

export default typography;
