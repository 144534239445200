import { Box, SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { formatCurrency } from '@platepal/utils';
import { t } from 'i18next';
import * as React from 'react';

import { CartItem as CartItemModel } from '../../../models';

const imageStyle: SxProps = {
  height: '2.5rem',
  width: '4rem',
  borderRadius: '4px',
  objectFit: 'cover',
  mr: 2,
};

export interface OrderHistoryProps {
  orders: CartItemModel[];
}

const OrderHistory: React.FC<OrderHistoryProps> = ({ orders }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography gutterBottom variant="h4" sx={{ mb: 2 }}>
        {t('cart.your-orders')}
      </Typography>
      {orders.map((order, index) => (
        <Box key={`cart-item-dialog-${index}`} mb={4}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center" flex="1">
              {order?.imageUrl && (
                <Box component="img" src={order.imageUrl} sx={imageStyle} />
              )}
              <Box>
                <Typography sx={{ fontWeight: 500 }} variant="subtitle2">
                  {order.name}
                </Typography>
                <Typography variant="body2" color="primary">
                  {formatCurrency(order.price.gross * order.quantity)}
                </Typography>

                {order.modifiers.map((modifier) => {
                  return (
                    <Typography
                      sx={{ fontWeight: 400 }}
                      variant="body2"
                      key={`cart-item-modifier-${modifier.modifierId}`}
                    >
                      {`×${modifier.quantity}`} {modifier.name} &nbsp;
                      {formatCurrency(modifier.price.gross * modifier.quantity)}
                    </Typography>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default OrderHistory;
