import { Box, Button, Stack, SxProps, Typography } from '@mui/material';
import { CallWaiterReasons } from '@platepal/interfaces';
import { t } from 'i18next';
import React, { useState } from 'react';

import CallWaiterConfirmDialog from '../../components/call-waiter-dialog';
import WaiterPaymentConfirmDialog from '../../components/waiter-payment-dialog';
import { useCustomerServiceActions } from '../../hooks/customer-service';
import { useAppSelector } from '../../hooks/store';
import MainLayout from '../../layout/main-layout';
import CallWaiter from './components/call-waiter';
import ServiceErrorScreen from './components/service-error-screen';
import WaiterPayment from './components/waiter-payment';

const imgStyle: SxProps = {
  maxHeight: '200px',
  mb: 8,
};

enum DialogService {
  CALL_WAITER,
  PAYMENT,
}

const WaiterService = () => {
  const [serviceDialog, setServiceDialog] = useState<DialogService | null>(
    null
  );
  const [serviceScreen, setServiceScreen] = useState<DialogService | null>(
    null
  );
  const { callWaiter, resetError } = useCustomerServiceActions();
  const { errorMsg } = useAppSelector((root) => root.customerService);

  // Get the current URL search parameters
  const params = new URLSearchParams(window.location.search);

  // Get the value of the 'restaurantID' parameter
  const restaurantID = params.get('restaurantId');

  // Get the value of the 'tableID' parameter
  const tableID = params.get('tableId');

  const navigateBackToMenu = () => {
    setServiceScreen(null);
    resetError();
  };

  const onDialogSubmit = (serviceType: CallWaiterReasons) => {
    const successHandler = () => {
      setServiceScreen(
        serviceType.startsWith('CALL_WAAITER')
          ? DialogService.CALL_WAITER
          : DialogService.PAYMENT
      );
    };
    setServiceDialog(null);

    if (restaurantID && tableID) {
      callWaiter(
        restaurantID,
        tableID,
        { reason: serviceType },
        successHandler
      );
    }
  };

  if (!serviceScreen && !errorMsg) {
    return (
      <>
        <CallWaiterConfirmDialog
          open={serviceDialog === DialogService.CALL_WAITER}
          onCancel={() => setServiceDialog(null)}
          onSubmit={onDialogSubmit}
          title="call-waiter.dialog.title"
          text="call-waiter.dialog.content"
          confirm="yes"
        />
        <WaiterPaymentConfirmDialog
          open={serviceDialog === DialogService.PAYMENT}
          onCancel={() => setServiceDialog(null)}
          onSubmit={onDialogSubmit}
          title="waiter-payment.dialog.title"
          text="waiter-payment.dialog.content"
          confirm="yes"
        />
        <MainLayout>
          <Box
            height="92%"
            sx={{ textAlign: 'center', px: 2 }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Box>
              <Box
                component="img"
                src="/img/service/customer-service.svg"
                alt={t('cart.empty-cart')}
                sx={imgStyle}
              />
              <Typography mb={2} variant="h3">
                {t('service.waiter-service.dialog-title')}
              </Typography>
              <Typography>
                {t('service.waiter-service.dialog-caption')}
              </Typography>
            </Box>
          </Box>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            sx={{ px: 2 }}
          >
            <Button
              onClick={() => setServiceDialog(DialogService.CALL_WAITER)}
              variant="contained"
              fullWidth
            >
              {t('service.waiter-service.call-waiter')}
            </Button>
            <Button
              onClick={() => setServiceDialog(DialogService.PAYMENT)}
              variant="contained"
              sx={{ ml: 2 }}
              fullWidth
            >
              {t('service.waiter-service.pay')}
            </Button>
          </Stack>
        </MainLayout>
      </>
    );
  }

  return (
    <MainLayout>
      {serviceScreen === DialogService.CALL_WAITER && (
        <CallWaiter onBack={navigateBackToMenu} />
      )}
      {serviceScreen === DialogService.PAYMENT && (
        <WaiterPayment onBack={navigateBackToMenu} />
      )}
      {errorMsg && (
        <ServiceErrorScreen errorMsg={errorMsg} onBack={navigateBackToMenu} />
      )}
    </MainLayout>
  );
};

export default WaiterService;
