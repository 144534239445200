import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

import { Cultured } from '../../config/theme/colors';
import LabelBottomNavigation from '../component/bottom-navigation';
import { bottomNavHeight } from './layout-config';

export interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = (props) => {
  // Calculate the content height dynamically based on the bottom navigation height
  const contentHeight = `${window.innerHeight - bottomNavHeight}px`;

  return (
    <Box sx={{ backgroundColor: Cultured }} component="main" height="100%">
      <Box sx={{ height: '100%', overflow: 'auto', maxHeight: contentHeight }}>
        {props.children}
      </Box>
      <LabelBottomNavigation />
    </Box>
  );
};

export default MainLayout;
