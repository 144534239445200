import { createSlice } from '@reduxjs/toolkit';

import { GetRestaurantDataError } from '../../models';
import { RestaurantState } from './restaurant-state';
import { getRestaurantData } from './thunks';

const restaurantInitialState: RestaurantState = {
  loading: false,
  errorMsg: null,
  restaurant: undefined,
  tableId: undefined,
  categories: [],
  menuItems: [],
  modifiers: [],
};
export const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState: restaurantInitialState,
  reducers: {
    resetError: (state: RestaurantState) => {
      state.errorMsg = null;
    },
    setTableId: (state: RestaurantState, action) => {
      state.tableId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRestaurantData.pending, (state: RestaurantState) => {
      state.loading = true;
      state.errorMsg = null;
    });
    builder.addCase(
      getRestaurantData.fulfilled,
      (state: RestaurantState, action) => {
        state.loading = false;
        state.restaurant = action.payload.restaurant;
        state.categories = action.payload.categories;
        state.menuItems = action.payload.menuItems;
        state.modifiers = action.payload.modifiers;
      }
    );
    builder.addCase(
      getRestaurantData.rejected,
      (state: RestaurantState, action) => {
        const appError = new GetRestaurantDataError(action.error);
        state.loading = false;
        state.errorMsg = appError.appMessage;
      }
    );
  },
});

export const restaurantActions = restaurantSlice.actions;
