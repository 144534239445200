import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { cartSlice } from './cart';
import { customerServiceSlice } from './customer-sevice';
import { orderSlice } from './order';
import { restaurantSlice } from './restaurant';

const rootReducer = combineReducers({
  restaurant: restaurantSlice.reducer,
  cart: cartSlice.reducer,
  order: orderSlice.reducer,
  customerService: customerServiceSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export default store;
