/* eslint-disable @typescript-eslint/no-floating-promises */

import { useMemo } from 'react';

import { CartItem } from '../../models';
import { cartActions } from '../../store/cart';
import { useAppDispatch } from '../store';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCartActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(
    () => ({
      resetCart: () => dispatch(cartActions.resetCart()),
      addItemToCart: (item: CartItem) => dispatch(cartActions.addToCart(item)),
      changeQuantity: (id: string, quantity: number) =>
        dispatch(cartActions.changeQuantity({ id, quantity })),
    }),
    [dispatch]
  );
};
