import {
  CategoryInterface,
  MenuItemInterface,
  ModifierDto,
  RestaurantInterface,
} from '@platepal/interfaces';

import { apiAxios } from '../axios';

export class RestaurantService {
  //   TODO @Emad change promise type for sure,
  //   TODO @Emad add a type to the get request
  public async getRestaurantData(id: string): Promise<{
    restaurant: RestaurantInterface;
    categories: CategoryInterface[];
    menuItems: MenuItemInterface[];
    modifiers: ModifierDto[];
  }> {
    const res = await apiAxios.get<{
      restaurant: RestaurantInterface;
      categories: CategoryInterface[];
      menuItems: MenuItemInterface[];
      modifiers: ModifierDto[];
    }>(`/menu/${id}`);
    return res.data;
  }
}
