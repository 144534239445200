import { FoodBankOutlined } from '@mui/icons-material';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import RestoreIcon from '@mui/icons-material/Restore';
import { SxProps } from '@mui/material';
import Badge from '@mui/material/Badge';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Stack from '@mui/material/Stack';
import { t } from 'i18next';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../hooks/store';
import { calculateCartItemsCount } from '../../../libs';
import { RoutePaths } from '../../../routes-path';
import { bottomNavHeight } from '../../main-layout/layout-config';

const rootStyle: SxProps = {
  position: 'fixed',
  bottom: 0,
  width: '100%',
  zIndex: 1000,
  height: `${bottomNavHeight}px`,
  boxShadow: '2px 0px 24px -2px rgba(0,0,0,0.16)',
};

const LabelBottomNavigation = () => {
  const { cartItems } = useAppSelector((root) => root.cart);
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  // TODO @Emad reset allredux error on navigation

  return (
    <BottomNavigation
      sx={rootStyle}
      showLabels
      value={location.pathname}
      onChange={handleChange}
    >
      <BottomNavigationAction
        label={t('navigation.menu')}
        value={RoutePaths.MENU}
        icon={<FoodBankOutlined />}
      />
      <BottomNavigationAction
        label={t('navigation.cart')}
        value={RoutePaths.SHOPPING_CART}
        icon={
          <Stack direction="row" spacing={1}>
            <Badge
              color="secondary"
              badgeContent={calculateCartItemsCount(cartItems)}
            >
              <LocalGroceryStoreIcon />
            </Badge>
          </Stack>
        }
      />
      <BottomNavigationAction
        label={t('navigation.orders')}
        value={RoutePaths.ORDERS}
        icon={<RestoreIcon />}
      />
      <BottomNavigationAction
        label={t('navigation.services')}
        value={RoutePaths.CALL_WAITER}
        icon={<RecordVoiceOverIcon />}
      />
    </BottomNavigation>
  );
};

export default LabelBottomNavigation;
