/* eslint-disable @typescript-eslint/no-floating-promises */

import { CreateOrderInterface } from '@platepal/interfaces';
import { useMemo } from 'react';

import { createOrder, orderActions } from '../../store/order';
import { useAppDispatch } from '../store';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useOrderActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(
    () => ({
      resetError: () => dispatch(orderActions.resetError()),
      createOrder: (
        restaurantId: string,
        tableId: string,
        orderDto: CreateOrderInterface,
        onSuccess: () => void
      ) =>
        dispatch(createOrder({ restaurantId, tableId, orderDto, onSuccess })),
    }),
    [dispatch]
  );
};
