import { CallWaiterDto } from '@platepal/interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { CustomerService } from '../../services';

const customerService = new CustomerService();

export const callWaiter = createAsyncThunk(
  'customerService/call-waiter',
  async (props: {
    restaurantId: string;
    tableId: string;
    callWaiterDto: CallWaiterDto;
    onSuccess: () => void;
  }): Promise<CallWaiterDto[]> => {
    const { restaurantId, tableId, callWaiterDto, onSuccess } = props;

    const res = await customerService.callWaiter(
      restaurantId,
      tableId,
      callWaiterDto
    );
    onSuccess();
    return res;
  }
);
