import { Price } from '@platepal/interfaces';
import { addHours } from 'date-fns';

import { CartItem } from '../../models';
import { addPrices, multiplyPrice } from '../price-lib';

const EXPIRY_HOURS = parseInt(
  process.env.REACT_APP_ORDER_STORAGE_EXPIRY_HRS || '5',
  10
);

export const calculateCartTotalPrice = (cartItems: CartItem[]): Price => {
  let totalPrice: Price = {
    net: 0,
    gross: 0,
    vat: 0,
    vatRate: 0,
  };

  for (const cartItem of cartItems) {
    totalPrice = addPrices(
      totalPrice,
      multiplyPrice(cartItem.price, cartItem.quantity)
    );
  }
  return totalPrice;
};

export const calculateCartItemsCount = (cartItems: CartItem[]): number => {
  return cartItems.reduce((acc, cartItem) => {
    return acc + cartItem.quantity;
  }, 0);
};

export const saveOrderToLocalStorage = (cartItems: CartItem[]): void => {
  const existingOrdersStr = localStorage.getItem('orders');
  let existingOrders: CartItem[] = [];
  if (existingOrdersStr) {
    existingOrders = JSON.parse(existingOrdersStr)?.orders || [];
  }

  const expiry = addHours(new Date(), EXPIRY_HOURS);
  const updatedOrders = {
    orders: [...existingOrders, ...cartItems],
    expiry,
  };

  localStorage.setItem('orders', JSON.stringify(updatedOrders));
};
