import { SxProps } from '@mui/material';

export const DialogPaperStyle: SxProps = {
  width: '100%',
  m: 0,
  position: 'absolute',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderTopRightRadius: 16,
  borderTopLeftRadius: 16,
  overscrollBehavior: 'none',
  pb: '10px',
};
