import { Price } from '@platepal/interfaces';

export const addPrices = (price1: Price, price2: Price): Price => {
  return {
    net: price1.net + price2.net,
    vat: price1.vat + price2.vat,
    vatRate: price1.vatRate + price2.vatRate,
    gross: price1.gross + price2.gross,
  };
};

export const multiplyPrice = (price: Price, num: number): Price => {
  return {
    net: price.net * num,
    vat: price.vat * num,
    vatRate: price.vatRate * num,
    gross: price.gross * num,
  };
};
