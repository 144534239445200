import {
  CategoryInterface,
  MenuItemInterface,
  ModifierDto,
} from '@platepal/interfaces';

import { GroupedItems } from '../../models';

export const getGroupedItemsByCategory = (
  categories: CategoryInterface[],
  items: MenuItemInterface[]
): GroupedItems[] => {
  const groupedItems: GroupedItems[] = categories.map((category) => ({
    categoryId: category.id,
    items: [],
  }));

  groupedItems.forEach((group) => {
    group.items.push(
      ...items.filter((item) => item.categoryId === group.categoryId)
    );
  });

  return groupedItems;
};

export const getModifiersOfItem = (
  modifiers: ModifierDto[],
  item: MenuItemInterface | null
): ModifierDto[] => {
  if (!item) {
    return [];
  }
  return modifiers.filter((modifier) =>
    item.modifierGroupsIds?.includes(modifier.id)
  );
};
