import React, { useEffect } from 'react';

import AlertError from './components/alert-error';
import { LegalFooter } from './components/LegalFooter';
import ScanQrCode from './components/scan-qr-code';
import { useRestaurantActions } from './hooks';
import { useActiveSession } from './hooks/active-session';
import PageLoadingIndicator from './layout/component/page-loading-indicator';
import Routes from './routes';
import { RoutePaths } from './routes-path';
import { DataPrivacy } from './views/legal/DataPrivacy';
import { Impressum } from './views/legal/Impressum';

const App = () => {
  const { getRestaurantData, setTableId } = useRestaurantActions();
  // Load restaurantId
  const path = window.location.pathname;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const restaurantId = urlParams.get('restaurantId');
  const tableId = urlParams.get('tableId');
  const justScanned = urlParams.get('qr');

  const isActive = useActiveSession(justScanned ?? '');

  useEffect(() => {
    if (restaurantId) {
      getRestaurantData(restaurantId);
    }
  }, [getRestaurantData, restaurantId]);

  useEffect(() => {
    if (tableId) {
      setTableId(tableId);
    }
  }, [setTableId, tableId]);

  if (path === RoutePaths.IMPRESSUM) {
    return <Impressum />;
  }

  if (path === RoutePaths.DATA_PRIVACY) {
    return <DataPrivacy />;
  }

  if (!restaurantId) {
    return (
      <>
        <AlertError errorMsg="errors.restaurant.no-id" mb={2} />
        <LegalFooter />
      </>
    );
  }

  if (!tableId) {
    return (
      <>
        <AlertError errorMsg="errors.restaurant.no-table-id" mb={2} />
        <LegalFooter />
      </>
    );
  }

  if (!isActive) {
    return <ScanQrCode />;
  }

  return (
    <>
      <PageLoadingIndicator />
      <Routes />
    </>
  );
};
export default App;
