import { createSlice } from '@reduxjs/toolkit';

import { CallWaiterServiceError } from '../../models/errors/customer-service';
import { CustomerServiceState } from './customer-service-state';
import { callWaiter } from './thunks';

const customerServiceInitialSLice: CustomerServiceState = {
  loading: false,
  errorMsg: null,
};

export const customerServiceSlice = createSlice({
  name: 'customerService',
  initialState: customerServiceInitialSLice,
  reducers: {
    resetError: (state: CustomerServiceState) => {
      state.errorMsg = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(callWaiter.pending, (state: CustomerServiceState) => {
      state.loading = true;
      state.errorMsg = null;
    });
    builder.addCase(callWaiter.fulfilled, (state: CustomerServiceState) => {
      state.loading = false;
    });
    builder.addCase(
      callWaiter.rejected,
      (state: CustomerServiceState, action) => {
        const appError = new CallWaiterServiceError(action.error);
        state.loading = false;
        state.errorMsg = appError.appMessage;
      }
    );
  },
});

export const customerServiceActions = customerServiceSlice.actions;
