import { createSlice } from '@reduxjs/toolkit';

import { CreateOrderError } from '../../models/errors/order';
import { OrderState } from './order-state';
import { createOrder } from './thunks';

const orderInitialSLice: OrderState = {
  loading: false,
  errorMsg: null,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState: orderInitialSLice,
  reducers: {
    resetError: (state: OrderState) => {
      state.errorMsg = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOrder.pending, (state: OrderState) => {
      state.loading = true;
      state.errorMsg = null;
    });
    builder.addCase(createOrder.fulfilled, (state: OrderState) => {
      state.loading = false;
    });
    builder.addCase(createOrder.rejected, (state: OrderState, action) => {
      const appError = new CreateOrderError(action.error);
      state.loading = false;
      state.errorMsg = appError.appMessage;
    });
  },
});

export const orderActions = orderSlice.actions;
