import {
  CategoryInterface,
  MenuItemInterface,
  ModifierDto,
  RestaurantInterface,
} from '@platepal/interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RestaurantService } from '../../services';

const restaurantService = new RestaurantService();

// TODO @Emad change promise type
export const getRestaurantData = createAsyncThunk(
  'restaurant/getData',
  async (props: {
    id: string;
  }): Promise<{
    restaurant: RestaurantInterface;
    categories: CategoryInterface[];
    menuItems: MenuItemInterface[];
    modifiers: ModifierDto[];
  }> => {
    const { id } = props;

    return await restaurantService.getRestaurantData(id);
  }
);
