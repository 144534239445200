import React from 'react';
import {
  MemoryRouter,
  Navigate,
  Route,
  Routes as ReactRouterRoutes,
} from 'react-router-dom';

import SuspenseRoute from './components/routes/suspense-route';
import { RoutePaths } from './routes-path';
import { DataPrivacy } from './views/legal/DataPrivacy';
import { Impressum } from './views/legal/Impressum';
import Orders from './views/orders';
import WaiterService from './views/service';

const Menu = React.lazy(() => import('./views/menu'));
const Cart = React.lazy(() => import('./views/cart'));

// TODO make cart lazy load
const Routes = () => {
  return (
    <MemoryRouter>
      <ReactRouterRoutes>
        <Route path={RoutePaths.IMPRESSUM} element={<Impressum />} />
        <Route path={RoutePaths.DATA_PRIVACY} element={<DataPrivacy />} />
        <Route
          path={RoutePaths.MENU}
          element={<SuspenseRoute component={<Menu />} />}
        />
        <Route
          path={RoutePaths.SHOPPING_CART}
          element={<SuspenseRoute component={<Cart />} />}
        />
        <Route
          path={RoutePaths.ORDERS}
          element={<SuspenseRoute component={<Orders />} />}
        />
        <Route
          path={RoutePaths.CALL_WAITER}
          element={<SuspenseRoute component={<WaiterService />} />}
        />
        <Route path="*" element={<Navigate to={RoutePaths.MENU} replace />} />
      </ReactRouterRoutes>
    </MemoryRouter>
  );
};

export default Routes;
