import { LinearProgress, SxProps } from '@mui/material';

import { useLoading } from '../../../hooks/loading';

const rootStyle: SxProps = {
  position: 'fixed',
  zIndex: 2000,
  top: 0,
  left: 0,
  right: 0,
};
const PageLoadingIndicator = () => {
  const loading = useLoading();

  if (!loading) {
    return null;
  }

  return <LinearProgress sx={rootStyle} variant="query" color="primary" />;
};

export default PageLoadingIndicator;
