import { use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import de from '../../locales/de.json';
// import en from '../../locales/en.json';

export const initTranslations = (): void => {
  use(LanguageDetector)
    .init({
      resources: {
        // en: {
        //   translation: en,
        // },
        de: {
          translation: de,
        },
      },
      supportedLngs: ['de'],
      fallbackLng: 'de',
      interpolation: {
        escapeValue: false,
      },
    })
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    .then(() => {})
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
};
