import { RefObject, useEffect, useState } from 'react';

import { CommonLib } from '../common-lib';

export interface OnScreenInfoInterface {
  isVisible: boolean;
  currentElement?: HTMLElement;
}

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

const useIntersectionObserver = function useIntersectionObserver(
  elementRef: RefObject<Element>,
  {
    threshold = 0,
    root = null,
    rootMargin = '0%',
    freezeOnceVisible = false,
  }: Args
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([theEntry]: IntersectionObserverEntry[]): void => {
    setEntry(theEntry);
  };

  useEffect(() => {
    const node = elementRef?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;
    const heightMargin =
      window.innerHeight -
      (node.parentElement?.parentElement?.firstElementChild?.clientHeight ??
        50) -
      20;
    const observerParams = {
      threshold,
      root,
      rootMargin: `0px 0px -${heightMargin}px 0px`,
    };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    elementRef?.current,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(threshold),
    root,
    rootMargin,
    frozen,
  ]);

  return entry;
};

export default function useOnScreen(
  ref: RefObject<HTMLElement>,
  onIsVisibleChanged: (onScreenInfo: OnScreenInfoInterface) => void
): OnScreenInfoInterface {
  const entry = useIntersectionObserver(ref, {});
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  let onScreen: OnScreenInfoInterface = { isVisible: false };
  if (ref.current) {
    onScreen = {
      isVisible: !!entry?.isIntersecting,
      currentElement: ref.current,
    };
    onIsVisibleChanged(onScreen);
  }

  return onScreen;
}

export const onCatIsVisibleChanged = (
  onScreen: OnScreenInfoInterface,
  onScreenValues: OnScreenInfoInterface[],
  setTabsValue: (value: number) => void
) => {
  // find element in stored array
  let indexOfOnScreenValue: number = onScreenValues.findIndex(
    (onscreen) => onScreen.currentElement?.id === onscreen.currentElement?.id
  );
  if (indexOfOnScreenValue === -1 && onScreen.currentElement) {
    onScreenValues.push(onScreen);
  }
  // Sort onScreenValues depends on index of category
  onScreenValues.sort(
    // eslint-disable-next-line
      (x, y) => x.currentElement?.dataset.index?.localeCompare(y.currentElement?.dataset.index!) ?? 0,
  );
  indexOfOnScreenValue = onScreenValues.findIndex(
    (onscreen) => onScreen.currentElement?.id === onscreen.currentElement?.id
  );
  // replace value of onScreen
  onScreenValues[indexOfOnScreenValue] = onScreen;
  // get last index where visible is true
  const indexOfLastVisibleForwards = CommonLib.findLastIndex(
    onScreenValues.slice(),
    (onScreenComperer) => onScreenComperer.isVisible
  );
  const sliceOnScreen = onScreenValues[indexOfLastVisibleForwards];
  if (sliceOnScreen && sliceOnScreen.isVisible) {
    onScreen = sliceOnScreen;
    setTabsValue(indexOfLastVisibleForwards);
  }
};

// export default function useOnScreen(ref: RefObject<HTMLElement>, onIsVisibleChanged: (onSCreenInfo: OnScreenInfoInterface) => void,lastOnScreenValue:OnScreenInfoInterface) :OnScreenInfoInterface {
//     const [isIntersecting, setIntersecting] = useState(false)

//     const observer = useMemo(() => new IntersectionObserver(
//         ([entry]) => setIntersecting(entry.isIntersecting)
//     ), [ref])

//     useEffect(() => {
//         observer.observe(ref.current!)
//         return () => observer.disconnect()
//     }, []);

//     const onScreen: OnScreenInfoInterface = { isVisible: isIntersecting, currentElement: ref.current! };
//     if(onScreen.isVisible !== lastOnScreenValue.isVisible){
//         onIsVisibleChanged(onScreen);
//     }
//     lastOnScreenValue = onScreen;

//     return onScreen;
// }

// export default function useOnScreen<T extends Element>(
//   ref: MutableRefObject<T>,
//   rootMargin = '0px',
//   onIsVisibleChanged: (onSCreenInfo: OnScreenInfoInterface) => void,
// ): OnScreenInfoInterface {
//   // State and setter for storing whether element is visible
//   const [isIntersecting, setIntersecting] = useState<boolean>(false);
//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         // Update our state when observer callback fires
//         setIntersecting(entry.isIntersecting);
//       },
//       {
//         rootMargin,
//       },
//     );
//     if (ref.current) {
//       observer.observe(ref.current);
//     }
//     return () => {
//       observer.unobserve(ref.current);
//     };
//   }, []); // Empty array ensures that effect is only run on mount and unmount

//   const onScreen: OnScreenInfoInterface = { isVisible: isIntersecting, currentElement: ref.current! };
//   onIsVisibleChanged(onScreen);
//   return onScreen;
// }
