import { Grid, Link, Typography } from '@mui/material';

import { RoutePaths } from '../routes-path';
import { Logo } from './Logo';

export const LegalFooter = () => {
  return (
    <Grid
      container={true}
      sx={{ backgroundColor: '#2A2B2D', p: 2 }}
      spacing={2}
    >
      <Grid item xs={12} md={6}>
        <Logo />
        <Typography variant="body2" sx={{ color: 'white' }}>
          Powered by Platepal
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <Typography gutterBottom variant="h6" sx={{ color: 'white' }}>
          Rechtliches
        </Typography>
        <Link
          style={{
            color: 'white',
            textDecoration: 'none',
            fontSize: '0.75rem',
          }}
          href={RoutePaths.IMPRESSUM}
        >
          Impressum
        </Link>
        <Link
          style={{
            color: 'white',
            textDecoration: 'none',
            fontSize: '0.75rem',
          }}
          href={RoutePaths.DATA_PRIVACY}
        >
          Datenschutz
        </Link>
      </Grid>
    </Grid>
  );
};
