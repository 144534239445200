import { addPrices, multiplyPrice } from "../price-lib";
import { ApiError, ModifierQuantityOutOfRangeError } from "../../errors";
import { modifiersEqual } from "../modifiers-lib";
// export const calculateOrderItemPrice = (
//   menuItems: MenuItemInterface[],
//   modifierOptions: ModifierOptionInterface[],
//   order: OrderItemInterface,
// ) => {
//   let itemTotalPrice: Price = { net: 0, gross: 0, vat: 0, vatRate: 0 };
//
//   const orderItem = menuItems.find((menuItem) => menuItem.id === order.orderedItemId);
//   if (!orderItem) {
//     return failure(new ApiError(`menuItem with id: ${order.orderedItemId} not found`, 404));
//   }
//
//   itemTotalPrice = addPrices(itemTotalPrice, orderItem.price);
//
//   order.modifiers.forEach((modifier) => {
//     const modifierOption = modifierOptions.find((modifierOption) => modifierOption.id === modifier.modifierId);
//     if (modifierOption) {
//       const modifierPrice = multiplyPrice(modifierOption.price, modifier.quantity);
//       itemTotalPrice = addPrices(itemTotalPrice, modifierPrice);
//     } else {
//       throw new ApiError(`modifierOption with id: ${modifier.modifierId} not found`, 404);
//     }
//   });
//
//   return itemTotalPrice;
// };
//TODO @Emad ask yousef how can i use it in frontend and backend
export const calculateOrderItemPrice = (menuItem, modifierOptions, order) => {
    let itemTotalPrice = { net: 0, gross: 0, vat: 0, vatRate: 0 };
    itemTotalPrice = addPrices(itemTotalPrice, menuItem.price);
    order.modifiers.forEach((modifier) => {
        const modifierOption = modifierOptions.find((modifierOption) => modifierOption.id === modifier.modifierId);
        if (modifierOption) {
            if (modifierOption.minSelection >= modifier.quantity &&
                modifier.quantity >= modifierOption.maxSelection) {
                throw new ModifierQuantityOutOfRangeError(`Modifier option quantity ${modifier.quantity} does not match the range (${modifierOption.minSelection},${modifierOption.maxSelection}) , modifierId: ${modifier.modifierId}, modifier name: ${modifierOption.name}`);
            }
            const modifierPrice = multiplyPrice(modifierOption.price, modifier.quantity);
            itemTotalPrice = addPrices(itemTotalPrice, modifierPrice);
        }
    });
    return itemTotalPrice;
};
export const mapOrdersToOrderItems = (orders, menueItems) => {
    const orderItemDtos = [];
    orders.forEach((order) => {
        var _a;
        if (!order.orderedItemId) {
            return;
        }
        const orderItemDtoOld = orderItemDtos.find((orderItemDto) => orderItemDto.orderedItemId === order.orderedItemId &&
            orderItemDto.seen === order.seen &&
            orderItemDto.tableId === order.tableId &&
            modifiersEqual(order.modifiers, orderItemDto.modifiers));
        if (orderItemDtoOld) {
            orderItemDtoOld.quantity++;
            orderItemDtoOld.ids.push(order.id);
            return;
        }
        const menueItem = menueItems.find((menueItem) => menueItem.id === order.orderedItemId);
        if (!menueItem) {
            throw new ApiError(`Menue Item with id:${order.orderedItemId} not found`, 500);
        }
        const orderItemDto = {
            ids: [order.id],
            completed: (_a = order.completed) !== null && _a !== void 0 ? _a : false,
            modifiers: order.modifiers,
            OrderItemName: menueItem.name,
            orderedItemId: menueItem.id,
            price: order.price,
            quantity: 1,
            seen: order.seen,
            tableId: order.tableId,
            ImageUrl: menueItem.imageUrl,
        };
        orderItemDtos.push(orderItemDto);
    });
    return orderItemDtos;
};
