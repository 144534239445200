import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Dialog,
  FormControl,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { TransitionProps } from '@mui/material/transitions';
import { CallWaiterReasons } from '@platepal/interfaces';
import { t } from 'i18next';
import * as React from 'react';
import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { SwipeableHandlers } from 'react-swipeable/es/types';

import { useAppSelector } from '../../hooks/store';
import IphoneDragIcon from '../../icons/iphone-drag-icon';
import RadioServiceSelector from '../services/components/radio-service-selector';
import { DialogPaperStyle } from './styles';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ enter: 400, exit: 400 }}
    />
  );
});

type ConfirmDialogProps = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (reasin: CallWaiterReasons) => void;
  title: string;
  text: string;
  confirm: string;
};

const WaiterPaymentConfirmDialog = ({
  open,
  onSubmit,
  onCancel,
  title,
  text,
  confirm,
}: ConfirmDialogProps) => {
  const [dialogBottomValue, setDialogBottomValue] = useState(0);

  const [reason, setReason] = useState<CallWaiterReasons | undefined>(
    undefined
  );

  const availablePaymentServices = useAppSelector(
    (root) => root.restaurant.restaurant?.availablePaymentServices
  );

  // handle swipeDown
  const onSwipedHandler: SwipeableHandlers = useSwipeable({
    onSwiped: (eventData) => {
      const currentTarget: HTMLElement = eventData.event
        .currentTarget as HTMLElement;
      if (eventData.deltaY >= 0.3 * currentTarget.offsetHeight) {
        setTimeout(() => {
          setDialogBottomValue(0);
        }, 400);
      } else {
        setDialogBottomValue(0);
      }
    },
    onSwiping: (eventData) => {
      if (eventData.deltaY > 0) {
        setDialogBottomValue(-eventData.deltaY);
      }
    },
  });

  const onClose = () => {
    setReason(undefined);
    onCancel();
  };

  return (
    <Dialog
      PaperProps={{
        sx: { ...DialogPaperStyle, bottom: dialogBottomValue },
      }}
      fullWidth
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Box sx={{ position: 'relative' }}>
        <Box {...onSwipedHandler}>
          <Box sx={{ px: 1.5 }}>
            <Typography sx={{ my: 2, pt: 2 }} variant="h3" component="p">
              {t(title)}
            </Typography>
            <Typography sx={{ fontWeight: 600 }}>{t(text)}</Typography>
            <Divider sx={{ my: 2 }} />
          </Box>
          <Box>
            <Box
              sx={{
                position: 'absolute',
                top: 7,
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              <IphoneDragIcon />
            </Box>
          </Box>
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', top: 9, right: 2 }}
          >
            <CancelIcon sx={{ fontSize: '1.3em' }} />
          </IconButton>
        </Box>
        {availablePaymentServices && availablePaymentServices.length && (
          <Box>
            <FormControl sx={{ width: '100%', mx: 1 }}>
              <RadioServiceSelector
                onClickReson={(res) => setReason(res)}
                services={availablePaymentServices}
              />
            </FormControl>
          </Box>
        )}
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            zIndex: 10,
            paddingTop: '10px',
            width: '100%',
            height: '70px',
          }}
        >
          <Box
            component="div"
            sx={{ px: 1.5, pt: 0.2 }}
            display="flex"
            gap={2}
            justifyContent="space-between"
          >
            <Button
              color="secondary"
              fullWidth
              variant="contained"
              onClick={onClose}
            >
              {t('back')}
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                onSubmit(reason ?? CallWaiterReasons.PAYMENT_DEFAULT);
                setReason(undefined);
              }}
              disabled={
                availablePaymentServices !== undefined &&
                availablePaymentServices.length > 0 &&
                reason === undefined
              }
            >
              {t(`${confirm}`)}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default WaiterPaymentConfirmDialog;
