import { SxProps } from '@mui/material';

export const DialogPaperStyle: SxProps = {
  width: '100%',
  m: 0,
  position: 'absolute',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderTopRightRadius: 16,
  borderTopLeftRadius: 16,
  overscrollBehavior: 'none',
  pb: '10px',
};

export const buttonStyleConfirm: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px',
  borderRadius: '8px',
  ml: 2,
  width: '100%',
  transition: 'box-shadow 120ms ease-in 0s',
  boxShadow:
    'rgb(0 0 0 / 6%) 0px 0px 0.75rem 0px, rgb(0 0 0 / 12%) 0px 0.75rem 0.75rem 0px',
};

export const buttonStyleClose: SxProps = {
  bgcolor: '#E00060',
  '&:hover': {
    bgcolor: '#e00060bd',
  },
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px',
  borderRadius: '8px',
  width: '100%',
  transition: 'box-shadow 120ms ease-in 0s',
  boxShadow:
    'rgb(0 0 0 / 6%) 0px 0px 0.75rem 0px, rgb(0 0 0 / 12%) 0px 0.75rem 0.75rem 0px',
};
