import { CreateOrderInterface, OrderItemInterface } from '@platepal/interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { OrdersService } from '../../services';

const orderService = new OrdersService();
export const createOrder = createAsyncThunk(
  'order/create-order',
  async (props: {
    restaurantId: string;
    tableId: string;
    orderDto: CreateOrderInterface;
    onSuccess: () => void;
  }): Promise<OrderItemInterface[]> => {
    const { restaurantId, tableId, orderDto, onSuccess } = props;

    const res = await orderService.createOrder(restaurantId, tableId, orderDto);
    onSuccess();
    return res;
  }
);
