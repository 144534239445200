import { Box } from '@mui/material';

import { LegalFooter } from '../components/LegalFooter';

type LegalLayoutProps = {
  children: React.ReactNode;
};

export const LegalLayout = ({ children }: LegalLayoutProps) => {
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box sx={{ px: 2 }}>{children}</Box>

      <LegalFooter />
    </Box>
  );
};
