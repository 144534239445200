import { Alert, Box, SxProps, Theme } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

export interface AlertErrorProps {
  errorMsg: string | null;
  sx?: SxProps<Theme>;
  mb?: number;
  mt?: number;
}

const AlertError: React.FC<AlertErrorProps> = (props) => {
  if (!props.errorMsg) return null;

  return (
    <Box mb={props.mb || 0} mt={props.mt || 0}>
      <Alert sx={props.sx} severity="error">
        {t(props.errorMsg)}
      </Alert>
    </Box>
  );
};

export default AlertError;
