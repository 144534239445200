import { Box, Button, SxProps, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

export interface OrderEmptyDialogProps {
  onClose: () => void;
}

const imgStyle: SxProps = {
  maxHeight: '200px',
  mb: 8,
};
const OrderEmpty: React.FC<OrderEmptyDialogProps> = ({ onClose }) => {
  return (
    <>
      <Box
        height="92%"
        sx={{ textAlign: 'center', px: 2 }}
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
      >
        <Box>
          <Box
            component="img"
            src="/img/orders/empty-orders.svg"
            alt={t('orders.empty')}
            sx={imgStyle}
          />
          <Typography mb={2} variant="h3">
            {t('orders.empty')}
          </Typography>
          <Typography>{t('orders.subtitle')}</Typography>
        </Box>
      </Box>
      <Box sx={{ px: 2 }}>
        <Button onClick={onClose} fullWidth variant="contained">
          {t('cart.add-items')}
        </Button>
      </Box>
    </>
  );
};

export default OrderEmpty;
